.bpmn-diagram {
    .MuiFab-root {
      margin-bottom: 7px;
      i {
        font-size: 20px;
      }
    }

  .bjs-container {
    .needs-discussion {
      &::before {
        content: 'hello'
      }
    }

    .needs-discussion:not(.djs-connection) .djs-visual> :nth-child(1) {
      // stroke: rgba(66, 180, 21, 0.7) !important;
      // fill: rgba(66, 180, 21, 0.7) !important;
      stroke: rgba(66, 180, 21, 0.7) !important;
      stroke-width: 7px !important;
      fill-opacity: 1 !important;
    }
  }
}

.bjs-powered-by-lightbox {
  z-index: 1300 !important;
}

@media (min-width: 320px) {
  .control-buttons {
    position: absolute;
    top: 30px;

    display: flex;

    right: 35px;

    .MuiButtonBase-root {
      background-color: $app-primary !important;
    }
  }
}

@media (min-width: 1024px) {
  .control-buttons {
    position: absolute;
    top: 30px;
    right: 40px;
    width: 50px;

    display: block;

    .MuiButtonBase-root {
      background-color: $app-primary !important;
    }
  }
}



