.formsengine-form {
  .horizontal-stepper-linear {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    padding: 24px;
    box-shadow: none;
    background-color: white;

    .horizontal-stepper {
      flex: 1;
      position: relative;
      padding-left: 8px;
      padding-right: 8px;

      &:first-child {
        padding-left: 0;
      }

      .divider {
        top: 20px;
        left: calc(-50% + 27px);
        right: calc(50% + 27px);
        position: absolute;
        flex: 1 1 auto;

        span {
          border-top-style: solid;
          border-top-width: 1px;
          display: block;
          border-color: #bdbdbd;
        }
      }

      .stepperlabel {
        display: flex;
        align-items: center;
        flex-direction: column;

        .steppercircle {
          width: 40px;
          height: 40px;
          align-items: center;
          justify-content: center;
          display: flex;
          background-color: rgba(0, 0, 0, 0.38);
          border-radius: 50%;

          i {
            font-size: 18px;
            color: white;
          }

          span {
            font-size: 16px;
            color: white;
          }
        }

        .stepperpagename {
          padding-top: 4px;
          text-align: center;
        }
      }

      &.active {
        .steppercircle {
          background-color: #3f51b5;
        }

        .stepperpagename {
          color: black;
          font-weight: bold;
        }
      }
    }
  }

  span.err-message {
    color: #f44366;
    font-size: 15px;
    padding-top: 10px;
    text-align: left;
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  span.help-block {
    color: #f44366;
  }

  .repeater-container {
    button.add-btn {
      padding: 7px 20px !important;

      .MuiButton-label {
        i {
          line-height: 30px;
          font-size: 20px;
        }

        span {
          font-size: 12px;
        }
      }
    }
  }

  .repeat-upload {
    label + .input-file {
      margin-top: 16px;
    }
  }

  .input-file {
    position: relative;
    margin-top: 8px;

    input {
      &.original-file-input {
        opacity: 0;
        width: 5%;
        max-width: none;
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
      }
    }

    .jr-btn-group {
      position: relative;
      top: -10px;
      left: 100px;
      width: auto;
      text-align: left;

      button {
        padding: 5px 15px !important;
        margin: 0px;

        .MuiButton-label {
          i {
            line-height: 23px;
            font-size: 15px;
          }

          span {
            font-size: 11px;
          }
        }

        &.icon-button {
          padding-left: 3px !important;
          padding-right: 3px !important;

          .MuiButton-label {
            i {
              line-height: 14px;
            }
          }
        }
      }
    }
  }

  .MuiSelect-select {
    &:focus {
      background-color: transparent !important;
    }
  }

  .MuiFormLabel-root,
  .MuiTypography-root {
    font-size: 0.875rem;
   
  }


  // formengine layout 

  .MuiInputLabel-formControl{
    font-size: 18px;
   
  }
  .MuiInputLabel-formControl{
    font-size: 18px;
    
  }

  .MuiInput-underline:disabled:before{
    border-bottom: 0 solid white;
  }

  .Mui-error:after {
    position: absolute;
    top: 50px;
  }

   .uploaded-file-name {
     position: absolute;
     top: 20px;
   }
   
   .MuiInputBase-input {
    color: black
   } 

  .MuiInputBase-input:disabled {
    font-size: 14px;
    color: darkgray;
    cursor: no-drop;
  }

  .d-flex {
    margin-top: -38px!important ;
  }

    label.radio {
      display: block;
      input {
        margin-top: -5px;
      }
    }

    button {
      &.disabled {
        color: rgba(0, 0, 0, 0.26);
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.12);
        cursor: default;
        pointer-events: none;
      }

      i {
        &.zmdi-hc-spin {
          background: transparent;
        }
      }
  }

  footer {
    margin-top: 15px;

    button.jr-btn.jr-btn-label {
      padding: 7px 10px !important;

      i {
        line-height: 32px;
      }

      i+span {
        margin-right: 38px;
      }
    }
  }
}
