#ui-datepicker-div { 
  background-color: $app-primary; 
  border-radius: 10px; 
  color: #fff;
} 

span.ui-state-default {
  color: #ffffff;
} 

a.ui-state-default {
  color: #fff;
  &:hover {
    color: #6c757d;
  }
}

.ui-datepicker-prev .ui-icon {
  background-image: url("../../assets/images/jquery-ui/ui-icons_ffffff_256x240.png");
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-position-y: -190px;
  background-position-x: -78px;
}
.ui-datepicker-next .ui-icon {
  background-image: url("../../assets/images/jquery-ui/ui-icons_ffffff_256x240.png");
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-position-y: -190px;
  background-position-x: -46px;
}
