	 //processDetails page
	.organisatie {
		 h4 {
			 margin-left: 24px;
		 }
		 h5 {
			margin-left: 24px;
		 }
	 }
	.table-one {
		font-size: 14px;
		margin-left: 24px;
		color: black;
		margin-bottom: 30px;
	  }
	  
	  .tableDetails {
		padding-left: 20px;
		color: black;
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		width: 82%;
	  }
	  
	  .buttonInfo {
		display: flex;
		justify-content: space-between;
		margin-left: 48px;
	    h1 {
		margin-left: -48px;
	  }
	    h5 {
		margin-left: -30px;
	  }
	  button {
		background-color: #216134eb;
		color: white;
		border-radius: 4px;
		margin-left: 5px;
	  }
	}
	  
	.table-two {
		color: black;
		margin-left: 24px;

		th {
			padding-left: 70px;
		  }

		  td span {
			margin-left: 50%;
		  } 
	}

	.table-third {
		color: black;
		margin-left: 24px;
		font-size: 14px;
	  }
	  
	  
	  .heading {
		padding: 20px 0px 5px 0px !important;
		font-size: 16px;
		text-decoration: underline;
	  }

	  b {
		color: #326d44;
	  } 
	  
	.MuiButton-containedPrimary {
		background-color: #326d44 !important;
	}

	// formengine form css //@at-root

	.stromen {
		font-weight: bold;
		color: #326d44;
		text-decoration: underline;
		font-size: 16px;
		margin-bottom: 10px;
	}
	.sublabel_name {
		display: flex;
		align-items: center;
		margin-top: -28px
	}

	.label_single {
		min-width: 65%;
	}

    .labels_name {
		display: flex;
		font-weight: bold;
		justify-content: space-around;
		margin-left: 320px;
  }
  
  .button-section {
    display: flex;
  }

	


	
	  
	